<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        {{
          inputs.language === 'en'
            ? 'What is the ground state electron configuration for the'
            : "Quelle est la configuration électronique de l'état fondamental de l'anion"
        }}
        <chemical-latex content="O^2-" />
        {{ inputs.language === 'en' ? 'anion?' : '?' }}
      </p>

      <electron-configuration-input
        v-model="inputs.electronConfig"
        :max-n="4"
        :max-occupation="10"
        :orbital-types="['s', 'p', 'd']"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ElectronConfigurationInput from '../inputs/ElectronConfigurationInput';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'Question495',
  components: {
    ChemicalLatex,
    ElectronConfigurationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        electronConfig: [],
      },
    };
  },
};
</script>
